import React, { forwardRef } from 'react';

import { IBlogPostFields } from '../../../../types/contentful';

import { getImageData } from '../../../utils/contentful';

import { Button } from '../../Button';
import { Box } from '../../Box';
import { Text } from '../../Text';

import { StyledImage, TextWrapper, AnchorLink } from './styles';

interface BlogPostCardProps extends IBlogPostFields {
  sysId?: string;
  tabIndex?: number;
}

const BlogPostCard = forwardRef(
  (
    { title, slug, heroImage, sysId, tabIndex }: BlogPostCardProps,
    ref: any
  ) => {
    return (
      <AnchorLink
        key={slug}
        id={sysId}
        ref={ref}
        href={`/blog/${slug}`}
        tabIndex={tabIndex}
        data-testid="anchor-link"
      >
        <Box>
          <StyledImage
            {...getImageData(heroImage)}
            width={450}
            height={300}
            layout="responsive"
            objectFit="cover"
            objectPosition="50% 50%"
          />
          <TextWrapper
            backgroundColor="pressPostBg"
            borderRadius={{
              0: ['0', '0', 'pressPost', 'pressPost']
            }}
            padding={{
              0: ['2xl', 'l', 'xl', 'l'],
              tablet: ['quickStartGuideTop', 'xl', 'xl', 'xl']
            }}
          >
            <Box paddingBottom="sm">
              <Text
                as="h3"
                fontWeight="h3"
                fontSize={{ 0: 'h3_0', tablet: 'h3_tablet' }}
                marginBottom={{ 0: 'xs' }}
              >
                {title}
              </Text>
            </Box>
            <Button buttonColor="dark">Read more</Button>
          </TextWrapper>
        </Box>
      </AnchorLink>
    );
  }
);

export default BlogPostCard;
