import styled from '../../theme/styled-with-theme';
import { mediaQueries } from '../../styles/media-queries';

export const MASONRY_CLASS = 'masonry-grid';
export const MASONRY_COL_CLASS = 'masonry-grid-column';

export const MasonryWrapper = styled('div')`
  .${MASONRY_CLASS} {
    display: flex;
    margin-left: ${({ theme }) => `-${theme.spacing.masonryGutter}`};
    width: auto;
  }
  .${MASONRY_COL_CLASS} {
    padding-left: ${({ theme }) => theme.spacing.masonryGutter};
    background-clip: padding-box;
  }

  ${mediaQueries.desktop} {
    .${MASONRY_CLASS} {
      margin-left: ${({ theme }) => `-${theme.spacing.masonryGutter_desktop}`};
    }

    .${MASONRY_COL_CLASS} {
      padding-left: ${({ theme }) => theme.spacing.masonryGutter_desktop};
    }
  }
`;
