import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Masonry from 'react-masonry-css';
import { useInView } from 'react-intersection-observer';

import { IBlogPost, IBlogPostFields } from '../../../../types/contentful';

import { breakpoints } from '../../../styles/media-queries';

import { Button } from '../../Button';
import { BlockContainer, Layout, BoxColumn } from '../../Layout';
import BlogPostCard from './BlogPostCard';

import { CardWrapper, LoadMoreButtonWrapper } from './styles';

import {
  MasonryWrapper,
  MASONRY_CLASS,
  MASONRY_COL_CLASS
} from '../../MasonryWrapper';

const NUMBER_TO_SHOW = 4;

export default function BlogPostFeed() {
  const [blogs, setBlogs] = useState<IBlogPost[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [activeBlogId, setActiveBlogId] = useState<string>();

  const blogRefs = useRef<any>([]);

  const { ref } = useInView({
    triggerOnce: true
  });

  const fetchBlogPostFeed = async (skip: number) => {
    const { data: feed } = await axios.post('/api/blog/feed', {
      skip,
      limit: NUMBER_TO_SHOW
    });

    setBlogs((prevBlogs: IBlogPost[]) => [
      ...prevBlogs,
      ...(feed as IBlogPost[])
    ]);
  };

  useEffect(() => {
    fetchBlogPostFeed(0);

    setIsMobile(
      typeof window !== `undefined` && window.innerWidth < breakpoints.tablet
    );
  }, []);

  // Get the ID of the next blog to set tab index
  useEffect(() => {
    if (blogs.length > 4) {
      const blogId = blogs[blogs.length - NUMBER_TO_SHOW].sys?.id;
      setActiveBlogId(blogId);
    }
  }, [blogs]);

  // Set focus to the active blog post
  useEffect(() => {
    if (activeBlogId) {
      blogRefs.current[blogs.length - NUMBER_TO_SHOW].focus();
    }
  }, [activeBlogId]);

  return (
    <BlockContainer
      as="section"
      marginTop={{ 0: 'ml', desktop: 'l' }}
      marginBottom={{ 0: 'blockMarginY', tablet: 'blockMarginY_tablet' }}
      data-blockid="blogFeedContainer"
      data-testid="blogFeedContainer"
    >
      <Layout>
        <BoxColumn
          size={{ 0: 12, desktop: 10 }}
          paddingTop={{ 0: 0, desktop: 'xs' }}
        >
          {isMobile ? (
            blogs.map(({ sys, fields }, idx: number) => (
              <CardWrapper
                key={sys?.id}
                ref={ref}
                data-testid={`blog-post-${idx}`}
              >
                <BlogPostCard
                  {...(fields as IBlogPostFields)}
                  sysId={`blog-post-${idx}`}
                  ref={(el) => (blogRefs.current[idx] = el)}
                  tabIndex={sys?.id === activeBlogId ? -1 : 0}
                />
              </CardWrapper>
            ))
          ) : (
            <MasonryWrapper>
              <Masonry
                breakpointCols={2}
                className={MASONRY_CLASS}
                columnClassName={MASONRY_COL_CLASS}
              >
                {blogs.map(({ sys, fields }, idx: number) => (
                  <CardWrapper key={sys?.id} data-testid={`blog-post-${idx}`}>
                    <BlogPostCard
                      {...(fields as IBlogPostFields)}
                      sysId={`blog-post-${idx}`}
                      ref={(el) => (blogRefs.current[idx] = el)}
                      tabIndex={sys?.id === activeBlogId ? -1 : 0}
                    />
                  </CardWrapper>
                ))}
              </Masonry>
            </MasonryWrapper>
          )}

          <LoadMoreButtonWrapper>
            <Button
              id="older-posts"
              buttonColor="dark"
              buttonHoverColor="light"
              onClick={() => {
                fetchBlogPostFeed(blogs.length);
              }}
            >
              Older posts
            </Button>
          </LoadMoreButtonWrapper>
        </BoxColumn>
      </Layout>
    </BlockContainer>
  );
}
