import styled from '../../../theme/styled-with-theme';
import { mediaQueries } from '../../../styles/media-queries';
import { animated } from 'react-spring';

import { Box } from '../../Box';
import { GlImage } from '../../Image';
import Link from '../../Link';

export const AnchorLink = styled(Link)`
  flex: 1 1 auto;
  text-decoration: none;
  color: ${({ theme }) => theme.color.black};
`;

export const StyledImage = styled(GlImage)`
  border-radius: ${({ theme }) => theme.borderRadius.quickStartGuideBox};
`;

export const TextWrapper = styled(Box)`
  margin-top: -${({ theme }) => theme.spacing.xl};
`;

export const LoadMoreButtonWrapper = styled('div')`
  text-align: center;
`;

export const CardWrapper = styled(animated.div)`
  margin: ${({ theme }) => theme.spacing.ml} 0;

  ${mediaQueries.tablet} {
    margin: ${({ theme }) => theme.spacing.l} 0;
  }
`;

interface Props {
  hidden: boolean;
  firstPressItem: boolean;
}

export const HiddenBox = styled(animated.div, {
  shouldForwardProp: (prop) => prop !== 'firstPressItem'
})<Props>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  margin: ${({ theme }) => theme.spacing.ml} 0;

  ${mediaQueries.tablet} {
    margin: ${({ theme }) => theme.spacing.l} 0;
  }
`;
