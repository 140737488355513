import React from 'react';

import { IPage } from '@/types/contentful';

import { PageProps } from '~/context/PageData';

import { Body } from '../Body';
import { HeroBlock } from './HeroBlock';
import { AltHeroBlock } from './AltHeroBlock';
import { PageBlocks } from './PageBlocks';
import BlogPostFeed from '../BlogComponents/BlogPostFeed';
import { ExperimentGroupContainer } from './ExperimentGroupContainer';

export default function Page({ pageData, query, slug }: PageProps) {
  const { fields } = (pageData as IPage) ?? {};

  return fields ? (
    <Body {...fields} query={query}>
      <ExperimentGroupContainer {...fields} />
      <HeroBlock {...fields} />
      <AltHeroBlock {...fields} />
      {slug === 'blog' ? <BlogPostFeed /> : <PageBlocks {...fields} />}
    </Body>
  ) : (
    <div />
  );
}
