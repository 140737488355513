import React from 'react';
import Blocks from './Blocks';
import { IPageFields } from '@/types/contentful';

export function ExperimentGroupContainer({
  experimentGroupContainer
}: IPageFields) {
  const { sys, fields } = experimentGroupContainer ?? {};

  return <Blocks sys={sys} fields={fields} />;
}
